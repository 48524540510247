<template>
  <div class="InvoiceProcessing">
    <el-dialog top="5vh" class="DepartmentBox" custom-class="InvoiceProcessingDig" width="80%" :close-on-click-modal="false" append-to-body :visible.sync="InvoiceProcessing" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">发票关联</span>
      </div>

      <!-- 发票表单 start -->
      <div class="main_form m-bottom-10">
        <el-card class="box-card" :body-style="{height: '80px', overflow: 'auto'}">
          <div slot="header" class="clearfix">
            <span class="cardTitle weight">发票填写</span>
          </div>
          <div class="form_fp">
            <el-form ref="ruleForm" :rules="rules" :inline="true" :model="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-form-item label="发票类型" prop="InvoiceType">
                <el-select v-model="ruleForm.InvoiceType" size="mini" style="width: 180px" placeholder="请选择">
                  <el-option
                    v-for="item in types"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="发票代码" prop="InvoiceCode">
                <el-input v-model="ruleForm.InvoiceCode" maxlength="20" size="mini" />
              </el-form-item>
              <el-form-item label="发票抬头" prop="InvoiceTitle">
                <el-input v-model="ruleForm.InvoiceTitle" disabled maxlength="100" size="mini" />
              </el-form-item>
              <el-form-item label="发票金额" prop="InvoiceAmount">
                <el-input v-model="ruleForm.InvoiceAmount" disabled maxlength="12" size="mini" />
              </el-form-item>
              <el-form-item label="发票号码" prop="InvoiceNo">
                <el-input v-model="ruleForm.InvoiceNo" maxlength="20" size="mini" />
              </el-form-item>
              <el-form-item label="发票日期" prop="strInvoiceDate">
                <el-date-picker
                  v-model="ruleForm.strInvoiceDate"
                  type="date"
                  size="mini"
                  value-format="yyyy-MM-dd"
                  :editable="false"
                  style="width: 180px"
                  placeholder="选择日期"
                />
              </el-form-item>
              <el-form-item label="校验码" prop="CheckCode">
                <el-input v-model="ruleForm.CheckCode" maxlength="20" size="mini" />
              </el-form-item>
              <el-form-item label="发票税率" prop="InvoiceTaxRate">
                <el-select v-model="ruleForm.InvoiceTaxRate" size="mini" style="width: 180px" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </div>
      <!-- 发票表单 end -->

      <!-- 交货单表格 start -->
      <!-- <div v-loading="isLoading" class="main_fp_content" style="overflow:auto"> -->
      <el-card v-loading="isLoading" class="box-card " :body-style="{height: height_a + 'px', overflow: 'auto'}">
        <div slot="header" class="clearfix">
          <span class="cardTitle weight"> 交货单</span>
        </div>
        <div class="content">
          <div v-for="(item, index) in list" :key="index">
            <div class="flex j-between a-center p-tb-10 p-lr-10" style="background:rgb(247,248,252)">
              <div class="f-1 flex j-start a-center" style="color:#333;font-size: 14px">
                <div class="Name">交货单号：{{ item.SapDeliveryID || item.DeliveryID }}</div>
                <div class="Name p-left-10">订单编号：{{ item.OrderID }}</div>
                <div class="Name p-lr-10">终端：{{ item.SellCompanyName }}</div>
                <div class="Name">买方：{{ item.CompanyName }}</div>
              </div>
              <div style="font-size: 12px;color:red">可开数量不能大于数量减去已开数量</div>
            </div>
            <div class="flex j-between p-tb-10">
              <el-table
                :header-cell-style="{background:'rgb(247,248,252)'}"
                :data="item.ListOrderDeliveryDetailsInfo"
                tooltip-effect="dark"
                :show-header="true"
                border
                @selection-change="selectionChange($event, index)"
              >
                <el-table-column v-if="!isEdit" :selectable="checkSelect" fixed="left" type="selection" width="40" />
                <af-table-column prop="InvCode" label="物料编号" show-overflow-tooltip />
                <el-table-column prop="MaterialName" label="物料名称" show-overflow-tooltip />
                <af-table-column prop="Specification" label="规格" show-overflow-tooltip />
                <af-table-column prop="BrandName" label="品牌" show-overflow-tooltip />
                <af-table-column prop="LotNum" label="批号" show-overflow-tooltip />
                <af-table-column prop="ExpiredDateShow" label="效期" show-overflow-tooltip />
                <af-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip />
                <af-table-column prop="Num" label="数量" show-overflow-tooltip />
                <af-table-column prop="InvoiceNum" label="已开数量" show-overflow-tooltip />
                <af-table-column v-if="!isEdit" prop="Quantity" label="可开数量" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-input-number v-model="scope.row.Quantity" :disabled="scope.row.Num === scope.row.InvoiceNum" size="mini" :controls="false" :min="1" :max="scope.row.Num - scope.row.InvoiceNum" />
                  </template>
                </af-table-column>
                <af-table-column prop="SapKBETR2" label="单价" show-overflow-tooltip />
                <af-table-column prop="Price" label="金额" show-overflow-tooltip>
                  <template slot-scope="scope">
                    {{ (scope.row.Num*scope.row.SapKBETR2).toFixed(2) }}
                  </template>
                </af-table-column>
              </el-table>
            </div>

          </div>
        </div>
      </el-card>
      <!-- </div> -->
      <!-- 交货单表格 end -->
      <div slot="footer" class="flex j-end a-center   btn">
        <el-button type="info" plain @click="closeDialg">关闭</el-button>
        <el-button v-loading="btnLoading" type="primary" @click="submit('ruleForm')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'InvoiceProcessing',
  data() {
    return {
      InvoiceProcessing: false,
      ruleForm: {
        IDX: 0,
        InvoiceCode: '',
        InvoiceStatus: '',
        InvoiceTitle: '',
        InvoiceNo: '',
        strInvoiceDate: '',
        CheckCode: '',
        InvoiceAmount: '',
        InvoiceTaxRate: '',
        InvoiceType: '',
        InvoiceDrawer: '',
        SyncFlag: 0,
        details: []
      },
      rules: {
        InvoiceType: [
          { required: true, message: '请选择发票类型', trigger: 'blur' }
        ],
        InvoiceTitle: [
          { required: true, message: '请输入发票抬头', trigger: 'blur' }
        ],
        InvoiceNo: [
          { required: true, message: '请输入发票号码', trigger: 'blur' }
        ],
        strInvoiceDate: [
          { required: true, message: '请选择发票日期', trigger: 'blur' }
        ],
        InvoiceAmount: [
          { required: true, message: '请输入发票金额', trigger: 'blur' }
        ],
        InvoiceTaxRate: [
          { required: true, message: '请选择发票税率', trigger: 'blur' }
        ]
      },
      types: [
        { value: 0, label: '普票' },
        { value: 1, label: '专票' }
      ],
      isEdit: false,
      options: [
        { value: 0.13, label: '13%' },
        { value: 0.03, label: '3%' },
        { value: 0, label: '0%' },
        { value: 0.01, label: '1%' },
        { value: 0.16, label: '16%' },
        { value: 0.05, label: '5%' },
        { value: 0.06, label: '6%' },
        { value: 0.07, label: '7%' }
      ],
      list: {},
      isLoading: true,
      btnLoading: false,
      selects: [],
      height_a: 0
    }
  },
  watch: {
    selects: {
      handler(a) {
        if (this.isEdit) return
        let brr = []
        let nums = 0
        const arr = a.filter(item => item.selectArr.length > 0)
        arr.map(item => {
          item.selectArr.map(item1 => {
            brr = brr.concat(item1)
          })
        })
        brr.map(item => {
          if (this.$minCommon.isRealNum(item.Quantity)) {
            nums += ((item.Quantity * item.SapKBETR2).toFixed(2) * 1)
          }
        })
        this.ruleForm.InvoiceAmount = nums === 0 ? '' : nums
      },
      deep: true
    }
  },
  methods: {
    open() {
      this.$nextTick(() => {
        this.height_a = (document.body.clientHeight * 0.8) - 330
      })
      this.isLoading = true
      this.InvoiceProcessing = true
    },
    closeDialg() {
      this.InvoiceProcessing = false
      this.$refs['ruleForm'].resetFields()
    },
    setData(data) {
      this.list = {}
      this.selects = []
      this.list = data
      this.ruleForm.InvoiceTitle = this.list[0].AgentCompanyName || this.list[0].CompanyName
      this.list.forEach((item, index) => {
        this.selects.push({
          index,
          IDX: item.IDX,
          selectArr: []
        })
      })
      this.isLoading = false
    },
    selectionChange(e, index) {
      this.selects[index].selectArr = e
    },
    // 修改发票
    editSetData(data) {
      this.isEdit = true
      this.list = {}
      this.selects = []
      this.list = data.DeliveryList
      // this.ruleForm.InvoiceTitle = this.list[0].AgentCompanyName || this.list[0].CompanyName
      for (const key in this.ruleForm) {
        this.ruleForm[key] = data[key]
      }
      this.list.forEach((item, index) => {
        this.selects.push({
          index,
          IDX: item.IDX,
          selectArr: []
        })
      })
      this.isLoading = false
    },
    // 设置是否禁选
    checkSelect(e) {
      if (e.Num === e.InvoiceNum) return false
      return true
    },
    // 整理所选交货单明细
    setDataOrder() {
      const arr = this.selects.filter(item => item.selectArr.length > 0)
      const brr = []
      arr.map(item => {
        item.selectArr.map(item1 => {
          brr.push({
            DeliveryIDX: item.IDX,
            DeliveryDetailsIDX: item1.IDX,
            Quantity: item1.Quantity
          })
        })
      })
      console.log('BRR', brr)
      return brr
    },
    // 设置同步
    setSync(status) {
      const _self = this
      this.ruleForm.SyncFlag = status
      this.btnLoading = true
      this.$api.SaveDeliveryInvoiceInfo(this.ruleForm).then(res => {
        console.log(res)
        _self.btnLoading = false
        if (res.RetCode !== '0') return this.$message.error(res.RetMsg)
        _self.closeDialg() // 成功后关掉弹窗
        _self.$emit('success')
        _self.$message.success(this.isEdit ? '保存成功' : '发票关联成功')
        return this.isEdit = false
      }).catch((err) => {
        _self.$message.error(err)
        return _self.btnLoading = false
      })
    },
    // 提交 关联发票
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        if (!this.isEdit) {
          const crr = this.setDataOrder()
          this.ruleForm.details = crr
          if (!this.ruleForm.details.length) return this.$message.info('请选择要关联发票的交货单明细')
          const drr = this.ruleForm.details.filter(item => item.Quantity === undefined)
          if (drr.length > 0) return this.$message.info('请输入可选数量')
        }
        if (this.ruleForm.SyncFlag !== 1) return this.setSync(0)
        this.$confirm('该发票信息已被修改，修改的发票信息是否需要重新同步？', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '同步',
          cancelButtonText: '不同步',
          type: 'warning'
        }).then(() => {
          this.setSync(0)
        }).catch((action) => {
          if (action === 'cancel') return this.setSync(1)
        })
      })
    }
  }
}
</script>
<style>
.el-card__header {
  background-color: #f1f9ff;
}
.el-range-editor--mini .el-range__close-icon, .el-range-editor--mini .el-range__icon {
  display: none !important;
}
</style>
<style lang="scss" scoped>
.DepartmentBox .el-dialog__body {
  height: 88vh;
  overflow: hidden;
}

.DepartmentBox .el-dialog__footer {
  padding: 10px ;
  padding-right: 20px;
}
.InvoiceProcessingDig {
    height: 88vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-content: center;
    // justify-content: space-between;
    .main_form {
        overflow: hidden;
        margin-top: 10px;
    }
   .main_fp_content {
        // flex:1;
         overflow: hidden;
    }
    .btn {
        height: 50px;
        width: 100%;
    }
}
</style>
