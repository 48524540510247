<template>
  <div class="Financialmodule">
    <PageHeader title="发票管理" />
    <div class="flex j-start a-start maxWidth p-lr-10">
      <el-form ref="ruleForm" :rules="rules" :inline="true" style="margin-top:10px" :model="form" label-width="70px">
        <el-form-item label="发票号码" prop="InvoiceNo">
          <el-input v-model="form.InvoiceNo" size="mini" />
        </el-form-item>
        <el-form-item label="交货单号" prop="DeliveryID">
          <el-input v-model="form.DeliveryID" size="mini" />
        </el-form-item>
        <el-form-item label="发票抬头" prop="InvoiceTitle">
          <el-input v-model="form.InvoiceTitle" size="mini" />
        </el-form-item>
        <el-form-item label="开票日期" prop="date">
          <el-date-picker
            v-model="form.date"
            style="width: 240px;"
            type="daterange"
            size="small"
            :editable="false"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="isChangeTime"
          />
        </el-form-item>
        <el-button type="primary" size="mini" style="margin-top:5px;margin-left:10px" @click="search">搜索</el-button>
        <el-button type="warning" size="mini" plain @click="restart">重置</el-button>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="p-lr-10 m-top-10">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :header-cell-style="headerCStyle"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 340px)"
        row-key="id"
        border
      >
        <el-table-column prop="InvoiceNo" label="发票号码" align="center" show-overflow-tooltip />
        <el-table-column prop="" label="交货单号" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.DeliveryRefDetails" :key="index">{{ item.SapDeliveryID || item.DeliveryID }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="InvoiceTitle" label="发票抬头" align="center" show-overflow-tooltip />
        <el-table-column prop="InvoiceAmount" label="发票金额" align="center" show-overflow-tooltip />
        <el-table-column prop="InvoiceTaxRate" label="税率" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.InvoiceTaxRate * 100 +'%' }}
          </template>
        </el-table-column>

        <el-table-column prop="InvoiceDate" label="开票日期" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate1(scope.row.InvoiceDate, 'yyyy-MM-dd') }}
          </template>
        </el-table-column>
        <el-table-column prop="UpdateTime" label="更新时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate1(scope.row.UpdateTime, 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column prop="SyncFlag" label="同步状态" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.SyncFlag === 1 ? '已同步' : (scope.row.SyncFlag === 2 ? '待重新同步' : '未同步') }}
          </template>
        </el-table-column>
        <el-table-column prop="Set" label="操作" align="center" width="260px" style="padding:0;margin:0">
          <template slot-scope="scope">
            <el-button size="mini" type="success" @click="editRow(scope.row.IDX)">修改</el-button>
            <el-button size="mini" type="danger" @click="del(scope.row.IDX)">删除</el-button>
            <el-button v-if="scope.row.SyncFlag === 1" :loading="scope.row.isSyncLoading" size="mini" type="warning " @click="syncData(scope.row)">重新同步</el-button>
          </template>
        </el-table-column>

      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <InvoiceProcessing ref="InvoiceProcessing" @success="successEvent" />
  </div>
</template>

<script>
import InvoiceProcessing from '../../components/invoiceProcessing.vue'
import PageHeader from '../../components/PageHeader.vue'
import tableMixin from '@/minxin/table-minxin'
export default {
  name: 'FinancialModule',
  components: {
    PageHeader,
    InvoiceProcessing
  },
  mixins: [tableMixin],
  data() {
    return {
      time: '',
      tableLoading: false,
      form: {
        date: [],
        InvoiceNo: '',
        InvoiceTitle: '',
        DeliveryID: '',
        BeginDate: '',
        EndDate: '',
        PageIndex: 1,
        PageSize: 20
      },
      rules: {},
      isSyncLoading: false
    }
  },
  methods: {
    // 搜索
    async search() {
      if (Array.isArray(this.form.date)) {
        this.form.BeginDate = this.form.date[0]
        this.form.EndDate = this.form.date[1]
      }
      this.getTableDataAsync(1)
    },
    // 重置
    restart() {
      this.form = {
        date: [],
        InvoiceNo: '',
        InvoiceTitle: '',
        DeliveryID: '',
        BeginDate: '',
        EndDate: '',
        PageIndex: 1,
        PageSize: 20
      }
      // this.getTableDataAsync()
    },
    // 重新同步
    syncData(row) {
      this.$set(row, 'isSyncLoading', true)
      this.$api.EditOrderInvoiceSyncFlag({ IDX: row.IDX, SyncFlag: 0 }).then(res => {
        this.$set(row, 'isSyncLoading', false)
        if (res.RetCode !== '0') return this.$message.error(res.RetMsg)
        this.getTableDataAsync(1)
        return this.$message.success('重新同步成功')
      }).catch(() => {
        return this.$set(row, 'isSyncLoading', false)
      })
    },
    // 关联发票成功回调
    successEvent() {
      this.getTableDataAsync()
    },
    // 修改
    editRow(IDX) {
      this.$api.GetOrderInvoiceInfoDetails({ IDX }).then(res => {
        // console.log(res)
        if (res.RetCode !== '0') return this.$message.error(res.RetMsg)
        this.$refs.InvoiceProcessing.editSetData(res.Message)
      })
      return this.$refs.InvoiceProcessing.open()
    },
    // 单个删除
    del(idx) {
      this.$confirm('删除该关联发票, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.DeleteOrderInvoiceInfo({
          idxs: idx
        }).then(res => {
          if (res.RetCode !== '0') return this.$message.error(res.RetMsg)
          this.getTableDataAsync(1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      }).catch(() => { })
    },
    // 时间选择器
    isChangeTime(e) {
      if (e === null) {
        this.form.date = []
        this.form.BeginDate = ''
        this.form.EndDate = ''
        return
      }
    },
    async getTableDataAsync(page, pageSize) {
      this.tableLoading = true
      this.form.PageIndex = page || 1
      this.form.PageSize = this.tablePageSize
      const response = await this.$api.GetOrderInvoiceInfoList(this.form)
      this.tableLoading = false
      if (response.RetCode !== '0') return this.$message.error(response.RetMsg)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      return response
    }
  }
}
</script>
<style lang='scss'>
.maxWidth {
  width: 100%;
  // max-height: 160px;
  flex-wrap: wrap;
  box-sizing: border-box;
  .top_view {
    .label {
      width: 120px;
      color: #333;
    }
  }
}
.maxWidth .el-form-item {
margin-bottom: 0
}
</style>
